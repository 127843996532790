const stories = [
  {
    url: "https://live.staticflickr.com/65535/53631547231_ac484091b1_c.jpg",
    type: "img",
  },
  {
    url: "https://live.staticflickr.com/65535/53631992315_8288b238af_c.jpg",
    type: "img",
  },
  {
    url: "https://live.staticflickr.com/65535/53631547211_5b4efb7725_c.jpg",
    type: "img",
  },
  {
    url: "https://live.staticflickr.com/65535/53631884849_1cb9b67f42_c.jpg",
    type: "img",
  },
  {
    url: "https://live.staticflickr.com/65535/53631884859_e8f02f9323_c.jpg",
    type: "img",
  },
];

module.exports = {
  stories
}

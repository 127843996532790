import "../../styles/load-screen.css";


export default function TransitionOut() {
  return (
    <div className="sticky">
      <div className="aux">
      <div className="screen1-out" />
      <div className="screen2-out" />
      <div className="screen3-out" />
      </div>
    </div>
  );
}

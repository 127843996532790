import "../../styles/mainImage.css";

export default function MainImage() {
  return (

      <div className="main-img_text">
        <div className="banner-text">

        <h1>Bienvenido a <strong>Peitho</strong></h1>
        <h2>Ropa hecha a mano, a tu medida!</h2>
        <h3>Con amor y dedicación </h3>
        </div>
      </div>

  );
}

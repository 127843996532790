import "../../styles/load-screen.css";


export default function TransitionIn() {
  return (
    <div className="sticky">
      <div className="aux">
      <div className="screen1-in" />
      <div className="screen2-in" />
      <div className="screen3-in" />
      </div>
    </div>
  );
}
